import React from 'react'
import {Grid} from '@mui/material'
import {CEO , CTO , About, Robot , Journey, Commisioner, Advisor1, Advisor} from '../../assets/img'
import './about.scss'

function Index() {
    
    const Scroll = () => window.scrollTo({behavior : 'smooth' , top : 0})

    return (
        <div className='about'>
            <div className='jumbotron' style={{backgroundImage : `url(${About})`}}>
                <h3 className='title'>ABOUT US</h3>
            </div>

            <div className='journey'>
                <h3 className='title'>
                    STORY JOURNEY
                </h3>

                <p className='body'>Since our establishment in 2016, Dattabot has used Big Data technology to provide innovative
                    solutions for clients from various industries; from Fast Moving Consumer Goods (FMGC)
                    companies, Financial Institution, even government institutions. This is the journey.
                </p>

                <div className='roadmap'>
                    <img src={Journey} alt='' />
                </div>
            </div>

            <div className='team'>
                <h3 className='team-title'>THE TEAM</h3>

                <div className='team-wrapper'>
                    <Grid container justifyContent="space-between">
                        <Grid item md={4} xs={5.5} sm={5.5}>
                            <div className='team-card'>
                                <div className='avatar'>
                                    <img src={CEO}  alt=''  />
                                </div>

                                <p className='name'>Regi Wahyu</p>
                                <p className='title'>CEO of Dattabot</p>
                            </div>
                        </Grid>

                        <Grid item md={4} xs={5.5} sm={5.5}>
                            <div className='team-card'>
                                <div className='avatar'>
                                    <img src={CTO} alt='' />
                                </div>

                                <p className='name'>Imron Zuhri</p>
                                <p className='title'>CTO of Dattabot</p>
                            </div>
                        </Grid>

                        <Grid item md={4} xs={5.5} sm={5.5} >
                            <div className='team-card'>
                                <div className='avatar'>
                                    <img src={Commisioner}  alt=''  />
                                </div>

                                <p className='name'>Julianto Sidarto</p>
                                <p className='title'>Commisioner of Dattabot</p>
                            </div>
                        </Grid>

                        <Grid item md={4} sm={5.5} xs={5.5}>
                            <div className='team-card'>
                                <div className='avatar'>
                                    <img src={Advisor1}  alt=''  />
                                </div>

                                <p className='name'>Raoul Oberman</p>
                                <p className='title'>Advisor</p>
                            </div>
                        </Grid>

                        <Grid item md={4} xs={5.5} sm={5.5}>
                            <div className='team-card'>
                                <div className='avatar'>
                                    <img src={Advisor}  alt=''  />
                                </div>

                                <p className='name'>Toto Sugiri</p>
                                <p className='title'>Advisor</p>
                            </div>
                        </Grid>

                        <Grid item md={4} xs={5.5} sm={5.5} >
                            <div className='team-card'>
                               
                            </div>
                        </Grid>
                    </Grid>
                    
                </div>

                <button className='see'>SEE ALL</button>
            </div>

            <div className='dattabot'>
                <div className='wrapper'>
                    <Grid container className='grid'>
                        <Grid item md={6} lg={6} xs={12} sm={5.5}>
                            <div className='logo'>
                                <img src={Robot} alt='' />
                            </div>
                        </Grid>

                        <Grid item md={6} lg={6} xs={12} sm={5.5}>
                            <div className='information'>
                                <h3 className='name'>MEET JERRY!</h3>
                                <p className='title'>Dattabot Prototype | Iteration 01</p>
                                <p className='body'>Created from the mind of one of our brilliant scientists in 2016, 
                                    Jerry is a robot that can gather and process all the knowledge humanity 
                                    has ever created, so it will have a deep understanding of the world around it, 
                                    and an uncanny understanding of what makes us tick. A potent combination that will 
                                    make it the most helpful assistant the world.
                                </p>
                            </div>
                        </Grid>
                    </Grid>
                </div>

                <span className='back' onClick={Scroll}>Back to top</span>
            </div>
        </div>
    )
}

export default Index
