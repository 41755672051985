import './App.css';
import Apps from './route'

function App() {
  return (
    <div className="App">
      <Apps />
    </div>
  );
}

export default App;
