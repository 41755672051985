import axios from "axios";

const Post = (path , data) => {
    const promise = new Promise( (resolve , reject) => {

        axios.post(path , data)
              .then( result => resolve(result.data))
              .catch( err => reject(err))
    })


    return promise
}


export default Post