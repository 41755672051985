import React from 'react'
import {Link} from 'react-router-dom'
import {Grid} from '@mui/material'
import {NotFound} from '../../assets/img'
import './notfound.scss'

function Index() {
    return (
        <div className='notfound'>
            <Grid className='wrapper' container>
                <Grid lg={5} md={6} sm={6} xs={12}>
                    <div className='logo-img'>
                        <img src={NotFound} alt='' />
                    </div>
                </Grid>
                <Grid lg={7} md={6} sm={6} xs={12}>
                    <div className='information'>
                        <h3 className='title'>Oops! Page not found</h3>
                        <p className='body'>Please go back to the homepage</p>
                        <Link to='/' className='link-direction'>BACK TO HOMEPAGE</Link>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Index
