import React from 'react'
import {Grid} from '@mui/material'
import {Link} from 'react-router-dom'
import {LogoImg} from '../../../assets/img'
import {Twitter , Linkedin , Instagram , FB} from '../../../assets/svg'
import './footer.scss'

function Index() {
    return (
        <div className='footer'>
            <div className='main'>
                <div className='footer-logo'>
                    <img className='footer-img' src={LogoImg} alt='' />
                </div>

                <div className='content'>
                    <Grid 
                        container 
                        rowSpacing={1} 
                        justifyContent='space-between'>

                        <Grid item xs={12} md={5} sm={5}>
                            <div className='footer-card'>
                                <p className='footer-title'>PT Mediatrac Sistem Komunikasi</p>

                                <p className='footer-subtitle'>
                                Equity Tower, 35th Floor
                                Jl. Jend. Sudirman, Kav. 52-53 (SCBD), 
                                Jakarta, 12190
                                Indonesia
                                </p>
                            </div>
                        </Grid>

                        <Grid item xs={12} md={4} sm={4} style={{width : '100%'}}>
                            <div className='footer-card'>
                                <p className='footer-title'>Contact</p>
                                <div className='row'>
                                    <div className='column'>
                                        <p >

                                            <span>Phone</span>
                                            <br />
                                            <br />
                                            <br />


                                            <span>Email</span>
                                            <br />
                                            <br />

                                            <span>Social Media</span>
                                        </p>
                                    </div>

                                    <div className='column'>
                                        <p>
                                            <span>(021) 29277999</span>
                                            <br />

                                            <span>(021) 29277999</span>
                                            <br />
                                            <br />
                                            

                                            <span>hello@dattabot.io</span>
                                            <br />
                                            <br />

                                            <span>
                                                <a href="https://facebook.com/dattabot.io/"><img src={FB} alt='' /></a>
                                                <a href="https://twitter.com/dattabot?s=21"><img src={Twitter} alt='' /></a>
                                                <a href="https://www.linkedin.com/company/mediatrac-fractal-collective-intelligence"><img src={Linkedin} alt='' /></a>
                                                <a href="https://www.instagram.com/dattabot/?hl=en"><img src={Instagram} alt='' /></a>
                                            </span>
                                            
                                            <br />
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </Grid>

                        <Grid item xs={12} md={3} sm={3}>
                            <div className='footer-card'> 
                                <p className='footer-title'>Menu</p>

                                <ul className='list'>
                                    <li className='list-item'><Link to='/' className='link'>Home</Link></li>
                                    <li className='list-item'><Link to='/about' className='link'>About Us</Link></li>
                                    <li className='list-item'><Link to='/tech' className='link'>Technology Solution</Link></li>
                                    {/* <li className='list-item'><Link className='link'>Career : Join Us!</Link></li> */}
                                    {/* <li className='list-item'><Link className='link'>Dattabot Dojo</Link></li> */}
                                </ul>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <h5 className='copyright'>© 2022 Dattabot. All Rights Reserved.</h5>
        </div>
    )
}

export default Index
