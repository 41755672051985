import React from 'react'
import {BrowserRouter as Router , Route , Switch } from 'react-router-dom'
import {Home , About , Tech , NotFound , Contact} from '../pages/index'
import {Header , Footer} from '../components/layout'
import { ScrollTop } from '../components/fiture'
 
function Index() {
    return (
        <div>
            <Router>
                <div style={{position : 'relative'}}>
                    <ScrollTop />
                    <Header />

                    <Switch>
                        <Route component={Home} path='/' exact />
                        <Route component={About} path='/about' exact />
                        <Route component={Tech} path='/tech' exact />
                        <Route component={Contact} path='/contact' exact />
                        <Route component={NotFound} path='**' />
                    </Switch>

                </div>

                <Footer />
            </Router>
        </div>
    )
}

export default Index
