import React from 'react'
import {useLocation} from 'react-router-dom'
import {Tech} from '../../components/fiture'
import {Techno} from '../../assets/img'
import './tech.scss'

function Index() {
    const {search} = useLocation()
    const params = new URLSearchParams(search) 
    
    
    return (
        <div className='tech'>
            <div style={{backgroundImage : `url(${Techno})`}} className='jumbotron'>
                <h3 className='title'>Tech Solution</h3> 
            </div>

            <section id="techsol">
                <div className='info'>
                    <Tech data={params ? params.get('category') : null } />
                </div>
            </section>
        </div>
    )
}

export default Index
