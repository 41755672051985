import React , {useState} from 'react'
import {useForm} from 'react-hook-form'
import {Grid , Modal} from '@mui/material'
import {contactUs} from '../../services'
import {Contact , Direction, Maps} from '../../assets/img'
import './contact.scss'

const styles = {
    modal : {
        minWidth: '80vw' ,
        minHeight: '230px' ,
        background: 'white' ,
        position: 'relative' ,
        boxSizing: 'border-box' ,
        padding: '20px' ,
        display: 'flex' ,
        alignItems: 'center' ,
        justifyContent: 'center'
    } ,

    title : {
        textAlign: 'center' ,
        fontSize: '24px' ,
        color: '#989898'
    } ,

    close : {
        position: 'absolute' ,
        right: '-10px' ,
        top: '-10px' ,
        borderRadius: '50%'
    }
}

function Index() {

    const {register , handleSubmit , formState : {errors}} = useForm()

    const [open , setOpen] = useState(null)

    const onSubmit = (data , e) => {
        contactUs(data).then( result => {

            setOpen(true)
            
            e.target.reset()
        })
        
    } 

    return (
        <div className='contact'>
            <div className='jumbotron' style={{backgroundImage : `url(${Contact})`}}>
                <h3 className='title'>CONTACT US</h3>
            </div>

            <div className='information'>
                <Grid className='wrapper' container>
                    <Grid item xs={12} sm={5} lg={4} md={5} >
                        <div className='map'>
                            <img className='img' src={Maps} alt='' />
                            <a className="direction" href="https://goo.gl/maps/pPMo1k4ucURbY767A">
                                <img src={Direction} alt='' />
                            </a>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={7} lg={8} md={7} >
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid className='wrap-info' item container direction="column" > 
                                <Grid item lg={12}>
                                    <div className='row-1'>
                                        <div className='column-1'>
                                            <input 
                                                placeholder="Your Name*" 
                                                {...register('name' , {
                                                    required : "Please enter your name" , 
                                                })} 
                                                
                                            />
                                            <p className={`error ${errors.name ? 'active' : ''}`} >{errors.name?.message}</p>
                                        </div>

                                        <div className='column-2'>
                                            <input 
                                                placeholder="Your Email*"
                                                {...register('email' , {
                                                    required : "Please enter your e-mail" , 
                                                    pattern : {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: "Enter a valid e-mail address",
                                                    }
                                                })}  
                                            />

                                            <p className={`error ${errors.email ? 'active' : ''}`} >{errors.email?.message}</p>
                                        </div>
                                    </div>
                                </Grid>

                                <Grid item lg={12}>
                                    <div className='row-2'>
                                        <div className='column-1'>
                                            <input 
                                                {...register('phone')} 
                                                placeholder="Phone Number" />
                                        </div>
                                            
                                        <div className='column-1'>
                                            <input {...register('institution')} 
                                                placeholder="Institution / Company" />
                                            
                                        </div>
                                    </div>
                                </Grid>
                                
                                <Grid item lg={12}>
                                    <div className="row-3">
                                        <textarea placeholder="Message" 
                                            {...register('message')} >
                                        </textarea>
                                       
                                    </div>
                                </Grid>

                                <Grid item lg={12}>
                                    <div className='row-4'>
                                        <button type="submit" className='action'>Submit</button>
                                    </div>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>

                { open && <Modal 
                    open={true}
                    sx={{display : 'flex' , justifyContent : 'center' , alignItems : 'center'}}>
                    
                    <div style={styles.modal}>
                        <h3 style={styles.title}>Thank You For Contact Us</h3>
                        
                        <button style={styles.close} onClick={() => setOpen(null)}> &#10006; </button>
                    </div>

                </Modal> 

             }
            </div>
        </div>
    )
}

export default Index
