import React , {useState} from 'react'
import Template from './item'
import {Data} from '../../../utilities/pages'

const getIndex = (data) => {

    const value =  Data.find( o => o.category === data)

    return value.id
}


function Index({data}) {

    const [number , setNumber] = useState(data ?  getIndex(data) : 0)

    const previous = () => setNumber(number - 1) 
    
    const next = () => setNumber(number + 1) 

    return (
        <Template 
            next={next} 
            prev={previous} 
            data={Data[number]}
            size={Data.length}
            number={number}    
        />
    )
}

export default Index
