const settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay : true ,
    autoplaySpeed : 4000 ,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 678,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      }
    ]
  };


  const Data = [
    {
      id : 0 ,
      category : "Agriculture" ,
      title : "Better Farming with Precision Agriculture" ,
      img : "./agriculture.png" ,
      content : [
        `Agriculture productivity is not optimized since the majority of farmers do not implement the best agronomic approach. To get better insights on how we can maximize farming potential, 
         we require comprehensive historical data of the farmland which is mostly unavailable. Stakeholders in the agriculture ecosystem are not interconnected resulting in sub-optimal collaboration.` ,
         `A groundbreaking mobile application that gathers data from farming operations in the field and provides insights to field officers and farmers. Satellite imaging to gather weather and other remote data. 
         Farming recommendation system generated by our adaptive analytics engine that can guide the farming process based on the best approach.` ,
         `In our experiment with more than 1,300 farmers in 1,700 hectares of land, we managed to increase crop yield by 80%, while reducing the use of farm inputs (fertilizers, etc.) by 50%.`
         
      ]
    } ,

    {
      id : 1 ,
      category : "Industrial Internet" ,
      title : "Industrial Optimization with the Internet-of-Things Platform" ,
      img : "./industri.png" ,
      content : [
      `Manufacturers are faced with cost volatility (raw materials, fuel, etc.), shrinking profit margins to maintain competitive prices, and changing buyer preferences to keep up with the competition. 
      They are also challenged with reduced cycle times to speed up production and downtime management that can cause production loss.` ,

      `Simple to use data analytics is the key to unlocking efficiency and optimized factory operations. Our Manufacturing Analytic Suite, powered by GE’s Industrial Internet Platform, Predix, enables you to predict, adapt, and react more quickly and effectively than ever before. Link design, engineering, manufacturing, supply chain, distribution, and services into one globally scalable intelligent system. 

      Dattabot addresses these challenges, enabling industrial companies to unlock the full potential of their enterprise information to optimize assets and processes and accelerate profitability. 
      
      Specifically, the Predix Platform enables experts in the organization to easily extract knowledge from a range of sources—including isolated data hidden in systems and various operational processes—and understand their complex relationships. This provides enterprises with a holistic view of assets and processes for faster optimization of operations.` ,

      `With the platform, major industries can achieve up to: Increase 10% Overall Equipment Effectiveness in Manufacturer Reduce 20% of Inventory Reduce time from order to delivery by 70% Reduce Rework by 15% Increase 35% of Oil Recovery Rate Reduction of 10% in emissions.`


      ]    
    } ,

    {
      id : 2 ,
      category : "Healthcare" , 
      title : `Digitalization in Healthcare Sector` ,
      img : "./healthcare.png" ,
      content : [
        `Currently, in Indonesia, there are no comprehensive data on drugs and a lack of standardization for healthcare documents be it medical reports, patient records, prescription forms, drug invoices, etc.` ,
        "We developed a system that allows healthcare organizations to automatically extract text and data from printed invoices using machine learning technology and minimal human supervision. The digitized output will be in a format that can be integrated into the organizational database and can be used for analytical purposes." ,
        `Our technology automatically recognizes printed medical documents and extract them into digital data with an accuracy of up to 75%.

        The system dramatically reduces the amount of manual data entry to process this drug invoice data, from 300 data entry operators to only 2-3 operators for quality checking. This will result in cost reduction, improved speed, and accuracy in the entire process of medical document digitalization.
        `
      ]
    } ,

    {
      id : 3 ,
      category : "Media" , 
      title : "Search and Recommendation Platform for Media and Digital Business" ,
      img : "./media.png" ,
      content : [
        "In digital businesses, collecting behavior data of consumer who visits your website may be easy. The problem lies in the cleanliness of the data and the fact that most business owners may not yet understand the importance of it, therefore they fail to utilize the data to optimize their operation and gain higher profit." ,
        "Through SPARSE (our search and recommendation platform), we want to help digital business owners to better understand their customers and focus on an increasing number of visitors and improving their sales. Offering individual visitors to receive personalized content recommendations relevant to their behavior and interest." ,
        `Sparse has been deployed as a Search and Recommendation platform for Qubicle (digital media website) and Tinkerlust (e-commerce website).

        Since Tinkerlust used Sparse’s open API to deliver personalized search results, they have generated more than 66,000 clicks, around 2 million impressions, 48% average Click Through Rate (CTR), and a 19% increase in conversion rate through their search engine.

        Sales have increased since the product recommendation from the search engine is now more relevant to the preferences of each consumer.`
      ]
    } ,

    {
      id : 4 ,
      category : "Telco" , 
      title : "Personalized Mobile Ads" ,
      img : "./telco.png" ,
      content : [
        "The rise of mobile penetration in Indonesia and the increased share of mobile impressions present a major untapped potential for mobile marketing, especially to deliver personalized ads for each mobile user." ,
        "Dattabot’s technology platform can process various mobile behavior data (such as call data records and deep packet data) to generate demographic, interest, and intention profiles of individuals. We can also enrich this data with other data sources to improve accuracy." ,
        `In our Proof-of-Concept with one of the Mobile Ads Exchange, our profiling technology was able to create improvement of CTR and total click performance between 2 to 7 times better than the conventional digital advertising campaign utilizing dynamic individual intent profiling.

        The conversion rate also increased up to 40% after we integrate the data with third-party data to build more comprehensive profiling.

        The advertising campaign performs better with more people actually clicking and purchasing products as our engine was able to deliver relevant advertising to the right audience, at the right moment.`
      ]
    } ,

    {
      id : 5 ,
      category : "Legal Industry" , 
      title : "Building a Digital Reference for Legal Regulation in Indonesia" ,
      img : "./legal.png" ,
      content : [
        "There is no up-to-date, comprehensive, and easily accessible legal knowledge center to find references for all types of regulation in Indonesia. This situation creates a problem for legal professionals in the research process, while also hindering the general public to have adequate knowledge of the legal system." ,
        "We developed a Natural Language Processing (NLP) technology to extract information from Indonesian legal documents and built network-graph data to process and track relationships between the regulations. All this information can be accessed through a user-friendly search engine specifically developed to search Indonesian regulations." ,
        `Our NLP technology managed to extract 90% of approximately 30,000 documents of laws and regulations from all over Indonesia, not only that, but our technology also managed to connect 80% approximately 164,000 legal relations in the country (the connection between one regulation and the other within the country)
        
        All this knowledge can be easily accessed through a search engine that surpassed Google’s accuracy in searching legal terms related keywords.`
      ]
    } ,

    {
      id : 6 ,
      category : "Retail/FMCG" , 
      title : "Smart Retail and FMCG" ,
      img : "./retail.png" ,
      content : [
        "For an FMCG company that distributes millions of units of their merchandise all over the nation, or a Retail company with thousands of stores across the country, simply monitoring and managing logistics, distribution, or sales force can be a very complex and time-consuming process. In most cases, the problem is not the lack of data but unconnected data which are scattered in different departments, systems, etc. making it a challenge to connect and integrate these data, and understand them in a meaningful way." ,
        "Assisted by our Territory Management platform, companies can map/visualize their assets, and identify potential target areas for sales/marketing. Beyond that, our platform also features a smart algorithm that can automatically formulate the most profitable routes and store visit schedules for every salesperson." ,
        "After implementing our Territory Management platform system, a major FMCG company in Indonesia was able to optimize their sales force management and sales outlet to increase sales revenue by an astonishing 40%."
      ]
    }
  ]


  const TechSol = [
    {
      category : "Agriculture" ,
      link : "/tech#techsol" ,
      img : "./agriculture.png"
    } ,

    {
      category : "IoT" ,
      link : "/tech?category=Industrial Internet#techsol" ,
      img : "./industri.png"
  
    } ,

    {
      category : "Healthcare" ,
      link : "/tech?category=Healthcare#techsol" ,
      img : "./healthcare.png"
  
    } ,

    {
      category : "Media" ,
      link : "/tech?category=Media#techsol" ,
      img : "./media.png"
  
    } ,

    {
      category : "Telco" ,
      link : "/tech?category=Telco#techsol" ,
      img : "./telco.png"
  
    } ,

    {
      category : "Retail/FMCG" ,
      link : "/tech?category=Retail/FMCG#techsol" ,
      img : "./retail.png"
  
    } ,

    {
      category : "Legal Industry" ,
      link : "/tech?category=Legal Industry#techsol" ,
      img : "./legal.png"
  
    }
  ]

  export {settings , Data , TechSol}