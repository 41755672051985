import React , {useState} from 'react'
import {Grid} from '@mui/material'
import './tech.scss'

function Item({data , prev , next , size , number}) {
    
    const [category , setCategory] = useState(0)

    const Scroll = () => window.scrollTo({behavior : 'smooth' , top : 0})

    const Prev = () => {
        setCategory(0)
        prev()
    }

    const Next = () => {
        setCategory(0)
        next()
    }


    return (
        <div className='item'>
            <Grid container className='wrapper'>
                <Grid item xs={12} sm={5} md={6} lg={4} className='col-1'>
                    <div className='logo'>
                        <img src={data.img} alt='' />
                    </div>
                </Grid>

                <Grid item xs={12} sm={7} md={6} lg={8} className='col-2'>
                    <div className='information'>  
                        <h5 className='category'>{data.category}</h5>
                        <h3 className='title'>{data.title}</h3>

                        <div className='action'>
                            <button className={category === 0 ? 'active' : ''} onClick={() => setCategory(0)}>Challenge</button>
                            <button className={category === 1 ? 'active' : ''} onClick={() => setCategory(1)}>Our Solution</button>
                            <button className={category === 2 ? 'active' : ''} onClick={() => setCategory(2)}>Result</button>
                        </div>

                        <div className='content'>

                            <div className='image'>
                            <button disabled={number === 0} 
                                    className={`prev ${number === 0 ? 'notActive' : 'active'}`} 
                                    onClick={Prev}>{'<'}</button>

                                <img src={data.img} alt='' />

                                <button 
                                    disabled={number + 1 === size} 
                                    className={`next ${number + 1 === size ? 'notActive' : 'active'}`} 
                                    onClick={Next}>{'>'}</button>
                            </div>
                            
                            { <p className='body'>{data.content[category]}</p>  }

                        </div>

                        <div className='pagination'>
                            <div className='action'>
                                <button 
                                    disabled={number === 0} 
                                    className={number === 0 ? 'notActive' : 'active'} 
                                    onClick={() => {
                                        setCategory(0)
                                        prev()}}>{'<'}</button>
                                
                                <button 
                                    disabled={number + 1 === size} 
                                    className={number + 1 === size ? 'notActive' : 'active'} 
                                    onClick={() => {
                                        setCategory(0)
                                        next()}}>{'>'}</button>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Grid>

            <span className='back' onClick={Scroll}>Back to top</span>
        </div>
    )
}

export default Item
