import React from 'react'
import {Grid } from '@mui/material'
import {Link} from 'react-router-dom'
import {HomeImg , Class , Traced , Rocket , BgHome ,
     Content1 , Content3, Content5 , Sinarmas, BTPN, Samsung, Mandiri, 
     BI, Indosat, WorldBank, kalbe, Pama, Pln, BMW, Sampoerna, Sreeya, Polri, Kemendikbud, Kemenkop, Kempar, Telkomsel, Philip, ARL, ARR } from '../../assets/img'
import {Eye} from '../../assets/svg'
import Slider from 'react-slick'
import {settings , TechSol} from '../../utilities/pages'
import Video from '../../assets/video.mp4'
import './home.scss'

const TechItem = ({data}) => (
    <div className='item'>
        <div className='item-img'>
            <img src={data.img} alt='' />
        </div>

        <div className='info'>
            <h3 className='title'>{data.category}</h3>
                <Link to={data.link} className='navigation'>
                    <img src={Eye} alt='' />
                        <span>View Detail</span>
                </Link>
        </div>
    </div>
)

function Index() {

    const Scroll = () => window.scrollTo({behavior : 'smooth' , top : 0})

    const Navright = () => {

        let elemnt = document.getElementById('product-list')

        let maxScroll = elemnt.scrollWidth - elemnt.clientWidth 

        elemnt.scrollBy(200 , 0)

        if(elemnt.scrollLeft === maxScroll || elemnt.scrollLeft > maxScroll)
        { 
            document.getElementById('btn-right').style.background = "rgba(211, 225, 233, 1)"

            document.getElementById('btn-left').style.background = "rgba(231, 87, 70, 1)"
        }
        else
        {
            document.getElementById('btn-left').style.background = "rgba(211, 225, 233, 1)"
            
            document.getElementById('btn-right').style.background = "rgba(231, 87, 70, 1)"
        }
    }

    const Navleft = () => {

        
        let elemnt = document.getElementById('product-list')

        elemnt.scrollBy(-200 , 0)

        if(elemnt.scrollLeft ===  0 )
        { 
            document.getElementById('btn-left').style.background = "rgba(211, 225, 233, 1)"

            document.getElementById('btn-right').style.background = "rgba(231, 87, 70, 1)"
        }
        else
        {
            document.getElementById('btn-right').style.background = "rgba(211, 225, 233, 1)"

            document.getElementById('btn-left').style.background = "rgba(231, 87, 70, 1)"
        }

    }

    return (
        <div className='home'>
           <div className='landing' style={{backgroundImage : `url(${HomeImg})`}}>

               <div className='video-wrap'>
                <video loop="true" autoplay="autoplay" muted>
                        <source src={Video} type="video/mp4"></source>
                </video>
               </div> 
               
               <div className='content'>
                   <h3 className='content-title'>WE UNLOCK THE VALUE OF YOUR DATA</h3>

                   <p className='content-body'>We are powered by the most advanced analytics technology
                      and the most comprehensive data library in Indonesia.
                    </p>

                    <Link to="/tech" className='content-action'>LEARN MORE</Link>
               </div>
           </div>

           <div className='technology'>
                <div className='content'>
                    <h3 className='content-title'>
                        <span>OUR</span>
                        <span>TECHNOLOGY</span>
                    </h3>

                    <p className='content-body'>Our ability to unlock the value within your data 
                        is powered by a platform built on top of three layers of sophisticated technologies : 
                        Knowledge Graph, Machine Learning, and Artificial General Intelligence.</p>

                    <div className='group'>

                        <Grid container className='wrapper'>
                            <Grid item md={5} sm={12} xs={12} className='column-1'>
                                <Grid md={12} sm={6} xs={6}>
                                    <div className='content-1'>
                                        <img src={Content1} alt='' />
                                    </div>
                                </Grid>

                                <Grid  md={12} sm={6} xs={6}>
                                    <div className='content-4'>
                                        <p className='body'>The robot cleans connects all types of your data from various sources, connecting it with one another to create a more complete knowledge
                                        center. Your data will also be enriched with the data from our massively rich library.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item md={3.5} xs={12} className='column-2'>
                                <Grid xs={6} md={12}>
                                    <div className='content-2'>
                                        <p className='body'>Once we have the ready-and-enriched data, we train it through
                                            our analytics and hyper-profiling engine, discovering hidden connections that 
                                            can give meaningful information.
                                        </p>
                                    </div>
                                </Grid>

                                <Grid xs={6} md={12}>
                                    <div className='content-5'>
                                        <img src={Content5} alt='' />
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid item md={3.5} xs={12} className='column-3'>
                                <Grid xs={6} md={12}>
                                    <div className='content-3'>
                                        <img src={Content3} alt='' />
                                    </div>
                                </Grid>

                                <Grid xs={6} md={12}> 
                                    <div className='content-6'>
                                        <p className='body'>Our technology will also 
                                        present you with relevant leads and recommendations that can help you achieve better results in your business.
                                        </p>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div> 
                </div>
           </div>


           <div className='solution'>
                
                    <Grid container className='content'>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <div className='information'>
                                <h3 className='title'>
                                    <span>
                                        OUR
                                    </span>

                                    <span>
                                        SOLUTION
                                    </span>
                                </h3>

                                <p className='body'>What Dattabot offers through our Core Technology is the harnassing of 
                                    analytical capability to bring solutions for challenges in various industries.
                                    We are active in offering solutions for the following sectors:</p>
                            </div>
                        </Grid>

                        <Grid item lg={8} md={8} sm={12} xs={12}>
                            <div id="product-list" className='product'>
                                <div className='list'>

                                {
                                    TechSol.map( (data , index) => <TechItem key={index} data={data} /> )
                                }

                            
                            </div>
                            </div>
                            <div className='nav-slider'>
                                <button id="btn-left" onClick={() => Navleft() }><img src={ARL} alt="" /></button>
                                <button id="btn-right" onClick={() => Navright() }><img src={ARR} alt="" /></button>
                            </div>
                        </Grid>
                    </Grid>
                
           </div>


           <div className='clients'>
                <h3 className='title'>
                    <span>OUR</span>
                    <span>CLIENTS</span>
                </h3>


                <div className='client-slider'>
                    <div className='main'>
                    <Slider arrows={false} {...settings}>

                    <div>
                        <img src={BTPN} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Sinarmas} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Samsung} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Mandiri} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={BI} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Indosat} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={WorldBank} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={kalbe} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Sampoerna} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Pama} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Pln} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={BMW} alt='' style={{margin : '0 auto'}} />
                    </div>
                        
                    <div>
                        <img src={Sreeya} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Polri} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Telkomsel} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Philip} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Kemendikbud} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Kemenkop} alt='' style={{margin : '0 auto'}} />
                    </div>

                    <div>
                        <img src={Kempar} alt='' style={{margin : '0 auto'}} />
                    </div>

                        
                    </Slider>
                    </div>
                </div>
           </div>

           <div className='dattabot'>
               <Grid container direction="row" className='row'>
                   <Grid item sm={12} md={5} xs={12}>
                        <div className='wrapper-img'>
                            <img src={Class} alt='' />
                        </div>
                    </Grid>

                    <Grid item sm={12} md={7} xs={12}>
                        <div className='content'>
                            <h3>DATTABOT DOJO!</h3>

                            <p>Dattabot presents our very own Business Learning
                                Unit : Dattabot Dojo! Here we strive to create a learning 
                                experience that combines business practices with data analysis to unlock its hidden potential.
                            </p>

                            {/* <a href="/dojo">VISIT THE DOJO {'>'}</a> */}

                            <img className='traced' src={Traced} alt="" /> 
                        </div>
                    </Grid>
                </Grid>
           </div>

           <div className='join' style={{backgroundImage : `url(${BgHome})`}}>

               <Grid container direction="row" className='wrapper' rowSpacing={2}>
                    <Grid item md={6} xs={8} sm={6}>
                        <h3 className='title'>JOIN US NOW!</h3>
                        <p className='paragraph'>Have any burning questions or simply want to
                            learn more about Dattabot? We’d love to share
                            our answers so come connect with us! </p>

                        {/* <Link to="/contact" className='action'>CONNECT WITH US</Link> */}
                        <a target='_blank' 
                            href="https://dattabot.notion.site/WE-RE-HIRING-d338ce18c96c4efb9d02864e3800ab14" 
                            style={{display : 'block' , width : 150 , marginBottom : 35}} 
                            className='action'>JOIN OUR TEAM</a>
                        <a href="mailto:hello@dattabot.io" className='action'>CONNECT WITH US</a>
                    </Grid>

                    <Grid md={12} xs={4} sm={4} item className='logo'>
                        <img src={Rocket} alt=''  />
                    </Grid>
                </Grid>

                

                <span className='back' onClick={Scroll}>Back to top</span>
           </div>
        </div>
    )
}

export default Index
