import React , {useState} from 'react'
import { NavLink} from 'react-router-dom'
import {LogoImg} from '../../../assets/img'
import './header.scss'

function Index() {

    const [isActive , setActive] = useState(false)

    const [colorChange , setChange] = useState(false)
    
    const onScroll = () => {
        if(!colorChange){
            if(window.scrollY >= 100){
                setChange(true)
            }
        }else{
            if(window.scrollY <= 100){
                setChange(false)
            }
        }
    }
    
    window.addEventListener('scroll' , onScroll)

    return (
        <div className={`header ${colorChange ? 'active' : ''}`}>
            <div className='header-logo'>
                <img src={LogoImg} className='header-img' alt='' />

                <div className={`hamburger-menu ${isActive ? 'open' : ''}` } onClick={() => setActive(!isActive)}>
                    <div className='hamburger-btn' />
                </div>
                
                
            </div>

            <div className={`header-link ${isActive ? 'active' : '' }`}>

                <ul className={`list ${isActive ? 'active' : null }`}>
                    <li className='list-item'><NavLink to='/' exact className='link'activeClassName='active-link' onClick={() => setActive(!isActive)}>Home</NavLink></li>
                    <li className='list-item'><NavLink to='/about' className='link' activeClassName='active-link' onClick={() => setActive(!isActive)}>About Us</NavLink></li>
                    <li className='list-item'><NavLink to='/tech' className='link' activeClassName='active-link' onClick={() => setActive(!isActive)}>Tech Solutions</NavLink></li>
                    <li className='list-item'><a target="_blank" href='https://dojo.dattabot.io' className='link' activeClassName='active-link' onClick={() => setActive(!isActive)}>Dattabot Dojo</a></li>
                    {/* <li className='list-item'><NavLink to='/dojo' className='link' activeClassName='active-link' onClick={() => setActive(!isActive)}>Dattabot Dojo</NavLink></li>
                    <li className='list-item'><NavLink to='/blog' className='link' activeClassName='active-link' onClick={() => setActive(!isActive)}>Blog</NavLink></li>
                    <li className='list-item'><NavLink to='/contact' className='link' activeClassName='active-link' onClick={() => setActive(!isActive)}>Contact Us</NavLink></li> */}
                </ul>

            </div>
        </div>
    )
}

export default Index
